@font-face {
  font-family: 'Chiller';
  src: url('./assets/font/Chiller.ttf') format('truetype');
}
@font-face {
  font-family: 'Bradhitc';
  src: url('./assets/font/bradhitc.ttf') format('truetype');
}
.h1_chiller{
  font-family: 'Chiller', sans-serif;
}
.h1_bradhitc{
  font-family: 'Bradhitc', sans-serif;
  font-size: 350px;
}
html {
  scroll-behavior: smooth;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo{
  width: 70px;
}

.main-page-bg {
  width: 100%;
  padding-bottom: 63px;
  text-align: center;
}

img.img-responsive.imgHome {
  margin-top: 40px;
  margin-top: 40px;
  width: 100%;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  color: white;
}

.App-link {
  color: #61dafb;
}

.main_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  font-size: calc(10px + 2vmin);
  border-top: 5px solid gray;
  /*border-bottom: 5px solid gray;*/
}


.hometext {
  padding-top: 140px;
}

nav.navbar.navbar-dark.bg-dark {
  margin-bottom: 0px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 5px solid gray;
}

.videos_list {
  background: #212020;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-radius: 10px;
  padding-top: 5px;
  margin-top: 15px;
  padding-bottom: 15px;
}

.container.videos_list {
  max-height: 600px;
  overflow-y: auto;
  white-space: nowrap;
  /* padding: 10px 0; */
  touch-action: pan-y;
  border-bottom: -14px solid #ccc;
 /* -ms-overflow-style: none;  /* IE and Edge */
 /* scrollbar-width: none;  */
}

.container.videos_list::-webkit-scrollbar {
  width: 10px;
  }
  
  .container.videos_list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 252, 252, 0.9);
  border-radius: 10px;
  }

div.scroller {
  width: 300px;
  height: 100px;
  overflow-x: scroll;
  scrollbar-color: black rgb(49, 49, 49);
}

video {
  border: 1px solid #fff;
  border-right: 2px solid #fff;
  border-radius: 10px;
}

.vid_row {
  background-color: #000000;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 1px solid;
  margin: 1px !important;
  margin-top: 0px !important;
}

.vid_details {
  padding-top: 5px;
  padding-bottom: 5px;
}

.vid_icon_pic {
  padding: 0 !important;
}

.video-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;
  transition: transform 0.3s;
}

.vid_plyer_page{
  padding-top: 50px;
  padding-bottom: 70px;
 
}
.vid_icon_pic img {
  border-radius: 0;
  border: 0px solid;
}

.videos_list h3 {
  padding: 12px;
  padding-left: 0;
}

.accordion-body,
button.accordion-button.collapsed {
  background: black;
  color: #fff;
}

.col-sm-8.vid_details {
  padding-bottom: 0;
  margin-bottom: -5px;
  text-transform: capitalize;
}

.vid_details p,
.current_vid_details h4,
.current_vid_details p {

  text-transform: capitalize;
}

.vid_details p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.vid_details_block {
  background: #212020;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

span.vid_catagory {
  font-size: 10px;
}

.catagory_tabs {
  margin-top: 20px;
  margin-bottom: 20px;

}

span.badge.bg-primary {
  padding-top: 8px;
  padding-bottom: 10px;
}

div#accordionExample {
  margin-top: 30px;
}

label {
  font-weight: 100;
  font-style: italic;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#category-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  touch-action: pan-y;
  border-bottom: 1px solid #ccc;
}

#category-chips {
  display: inline-flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.badge {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #f1f1f1;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.badge[aria-selected="true"] {
  background-color: #0073e6;
  color: white;
}

.badge:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

/* Scrollbar styles for webkit browsers */
#category-scroll-container::-webkit-scrollbar {
  height: 6px;
}

#category-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#category-scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

#category-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.footer {
  background: rgba(0, 0, 0, 0.9);
  min-height: 80px;
  /* margin-bottom: 50px; */
  /* margin-top: 50px; */
  padding-top: 30px;
  color: #fff;
  /* border: 1px solid #fff; */
  border-right: none;
  border-left: none;
  border-top: 5px solid gray;

}
img.footerLogo {
  width: 100px;
}



.social {
  font-size: 25px;
  margin-right: 20px;
  color: #fff;
  float: right;
  padding-bottom: 25px;

}

button.btn.btn-danger.banner_btn {
  margin-top: 21px;
  height: 50px;
  border-radius: 0;
  font-size: 20px;
}

.vd_list_h4{
  background: #212020;
  padding: 10px;
  /* margin-bottom: 0px; */
  margin: 0;
  margin-bottom: -15px;
}

@media only screen and (max-width: 970px) {
  .h1_bradhitc{
    font-family: 'Bradhitc', sans-serif;
    font-size: 250px;
  }
  .hometext {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 722px) {
  .h1_bradhitc{
    font-family: 'Bradhitc', sans-serif;
    font-size: 200px;
  }
  .hometext {
    padding-top: 50px;
  }
  .main-page-bg {

    padding-left: 15px;
    padding-right: 15px;
    min-height: 80vh;
  
  }
}

@media only screen and (max-width: 560px) {
  .h1_bradhitc{
    font-family: 'Bradhitc', sans-serif;
    font-size: 120px;
  }

  .col-sm-4.vid_icon_pic {
    width: 30%;
  }

  .col-sm-8.vid_details {
    width: 70%;
  }

}
@media only screen and (max-width: 425px) {
  .main-page-bg {
      min-height: 221px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: 0px;
      padding-left: 15px;
      padding-right: 15px;
      min-height: 80vh;
    
  }
  .hometext {
    padding-top: 50px;
    text-align: center;
}
}

@media only screen and (max-width: 325px) {
  .main-page-bg {
      min-height: 221px;
      padding-top: 30px;
      padding-bottom: 30px;
    
  }
  .h1_bradhitc{
    font-family: 'Bradhitc', sans-serif;
    font-size: 80px;
  }

  /*.main-page-bg h4, .main-page-bg h1{
    display: none;
  }*/
}

/********************* Slider ******************/
.centeredtext {
  text-align: center;
}

.grid {
  font-size: small;
  align-items: center;
  grid-template-columns: auto 1fr 1fr 1fr;
}

.slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slider > div {
  margin: 10px;
  font-size: small;
}

:root {
  --indicategreen: #00d563;
  --indicatered: #fd2f51;
  --indicatorcolour: var(--indicategreen);
}

.indicator {
  display: inline-block;
  margin: 15px;
  font-size: small;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--indicatorcolour);
  border: 1px solid darken(var(--indicatorcolour), 5%);
  color: var(--indicatorcolour);
  animation: blink 3s infinite;
}

.alertcolour {
  --indicatorcolour: var(--indicatered);
}

@keyframes blink {
  0% {
    box-shadow: 0 0 10px;
  }
  50% {
    box-shadow: 0 0 30px;
  }
  100% {
    box-shadow: 0 0 10px;
  }
}

/******************* End Slider **************/

input {
  appearance: none;
  background: transparent;

  margin: 0;
}

input,
span {
  grid-row: 1;
  grid-column: 1;
}

#bitrate-control {
  height: 20px;
  border: 1px solid rgb(255, 255, 255);
  background: linear-gradient(to right, var(--pico-primary-background), var(--pico-primary-background) var(--start), hsl(120 50% 50% / 0%) var(--end)),
    linear-gradient(to right, transparent var(--start), white var(--end)), var(--chevron);
  border-radius: 10px;
}

/* Chrome, Safari, Opera, and Edge Chromium */
input[type='range']::-webkit-slider-runnable-track {
  background: rgba(128, 128, 128, 0.089);
}
/* Firefox */
input[type='range']::-moz-range-track {
  background: transparent;
}

.row.vid_details_block {
  margin-left: 0px;
  margin-right: 0px;
}
.col-sm-1.indi {
  margin-top: 10px;
}

.form-range {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: var(--range-color);
  border-radius: 5px;
  outline: none;
  transition: background 0.3s;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #007bff;
  cursor: pointer;
}

.form-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #007bff;
  cursor: pointer;
}

.form-select-sm {
  padding-top: .1rem;
  padding-bottom: .1rem;

}

label {
  margin-bottom: 1px;
  margin-left: 6px;
}